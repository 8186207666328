<template>
  <div class="footer">
    <router-link to="home" class="_img"><img src="../../assets/img/logo/logo-white.png" alt=""></router-link>
    <!-- <div class="active">For inquiries purpose: Support@amusement.ai</div> -->
    <div class="content">
      <div>
        <div>© 2020 The Game L.L.C All rights reserved</div>
        <span><a href="javascript:;">Privacy Policy</a> | <a href="javascript:;">Terms of Service</a></span>
      </div>
      <router-link to="home" class="img"><img src="../../assets/img/logo/logo-white.png" alt=""></router-link>
    </div>
  </div>
</template>
<script>
export default {
  
  computed: {
    ...Vuex.mapGetters([
      "webName"
    ]),
  }
  
}
</script>
<style lang="stylus" scoped>
.footer{
  position: absolute;
  width: 100%;
  font-size: $Fts5;
  color: $Cf;
  background-color: #000;
  padding: .48rem .86rem;
  box-sizing: border-box;
  text-align: left;
  & > div {
    flex(column-reverse, nowrap);  
  }
  & .content{
    flex(row-reverse, nowrap,flex-end,center);
    margin-top: .18rem;
    & > div{
      padding-left: .24rem;
      border-left: .01rem solid $Cf;  
      & div{
        margin-bottom: .06rem;  
      }
    }
  }
  // height: 1rem;
  & span a {
    color: $Cf;
    margin-top: .1rem;
  }
  & .active{
    font-size: $Fts4;
  }
}
img{
  width: 1.5rem;
  margin-right: .24rem;
}
._img{
  display: none;
}

@media screen and (max-width: 980px){
  .footer{
    text-align: center;
    padding: 0.36rem 0.24rem;
    & div{
      margin: 0 auto;
    }
    & .img{
      display: none;
    }
    & ._img{
      display: inline-block;
      margin-bottom: .24rem;
      & img{
        width: 1.4rem;  
      }
    }
    & .content{
      margin-top: .14rem;
      & > div{
        padding-left: 0;
        border-left: none;
      }
    }
  }
}
</style>